import React from "react"
import styled, { keyframes } from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"

const DividerContainer = styled.div`
  overflow: hidden;
  height: 57px;
  width: 100%;
  display: flex;
  background: ${colors.green};
  position: relative;
  max-width: 100vw;
`

const InfiniteAnimation = () => keyframes`
  0% {
   transform: translateX(0%)
  }

  100% {
   transform: translateX(-50%)
  }


`

const Divider = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  animation: ${InfiniteAnimation} ${props => `${props.pointCount * 5}s`} linear infinite;
  position: absolute;
  top: 0;
  left: 0;

  div {
    display: flex;

  }
`

const Point = styled.div`
  ${fonts.labGrotesqueBold};
  color: ${colors.white};
  font-size: 19px;
  line-height: auto;
  letter-spacing: 3.8px;
  text-transform: uppercase;
  display: inline-block;
  white-space: nowrap;
  margin-right: 40px;
`

const LineDivider = (props) => {
  const { points } = props
  return (
    <DividerContainer>
      <Divider pointCount={points.length}>
        <div>
          {points.map((point, index) => {
            return <Point key={index}>{point.point}</Point>
          })}
        </div>
        <div>
          {points.map((point, index) => {
            return <Point key={index}>{point.point}</Point>
          })}
        </div>
      </Divider>
    </DividerContainer>
  )
}

export default LineDivider
