import React, { useState } from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import ContentContainer from "../Core/Container/ContentContainer"
import Star from "../../resources/img/ui/star.svg"
import Add from "../../resources/img/ui/dark/plus-dark.svg"
import Remove from "../../resources/img/ui/dark/line-dark.svg"
import { Col, Row } from "styled-bootstrap-grid"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { getLocalisedProductPrice } from "../../services/product/price"
import AddToBagButton from "./AddToBagButton"
import Mobile from "./gallery/Mobile"
import Desktop from "./gallery/Desktop"
import PlanPicker from "../Product/PlanPicker"

const IntroContainer = styled.div`
  margin: auto;
  margin-top: 34px;
  margin-bottom: 85px;
  max-width: 1600px;
  @media (min-width: ${breakpoints.lg}) {
    padding: 0 56px 0 69px;
  }
  @media (min-width: ${breakpoints.xxl}) {
    padding: 0 112px 0 138px;
  }
`

const ProductInfo = styled.div`
  @media (min-width: ${breakpoints.xl}) {
    max-width: 550px;
    margin-left: auto;
    padding-left: 40px;
  }
  @media (min-width: ${breakpoints.xxl}) {
    max-width: 510px;
    padding-left: 0;
  }
  @media (min-width: ${breakpoints.max}) {
    max-width: 600px;
  }
`

const ProductName = styled.h1`
  ${fonts.canelaThin};
  font-size: 40px;
  line-height: 100%;
`

const SubHeader = styled.div`
  ${fonts.labGrotesqueLight};
  font-size: 16px;
  line-height: 25px;
  margin-top: 10px;
`

const Reviews = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 16px;
  line-height: 25px;
  margin-top: 17px;

  img {
    width: 15px;
    height: 15px;
    margin-right: 9px;
    :last-of-type {
      margin-right: 0;
    }
  }
  span {
    margin-left: 13px;
  }
`

const PriceSection = styled.div`
  display: flex;
  margin-top: 23px;

  @media (min-width: ${breakpoints.md}) {
    padding-bottom: 27px;
    border-bottom: 1px solid ${colors.grey};
  }
`

const Price = styled.div`
  ${fonts.canelaThin};
  font-size: 40px;
  line-height: 100%;
`

const OriginSize = styled.div`
  ${fonts.labGrotesqueLight};
  font-size: 16px;
  line-height: 20px;
  margin-left: 18px;
`

const AddToCartContainer = styled.div`
  margin-top: 46px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 34px;
    width: 100%;
    display: flex;
  }
`

const Quantity = styled.div`
  border: 1px solid ${colors.grey};
  border-radius: 15px;
  ${fonts.labGrotesqueBold};
  font-size: 19px;
  letter-spacing: 3.8px;
  line-height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 11px;
  height: 100%;
  min-width: 113px;
  @media (min-width: ${breakpoints.md}) {
    margin-right: 8px;
    margin-bottom: 0;
  }
`

const QuantityButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  width: 40px;
  height: 55px;
  padding: 15px 16px;
  cursor: pointer;
  img {
    width: 8px;
    height: 100%;
  }
`

const AddToCart = styled.div`
  button {
    min-width: 100%;
  }

  @media (min-width: ${breakpoints.md}) {
    width: 100%;
  }
`

const ProductDetails = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 16px;
  line-height: 25px;
  margin-top: 51px;

  h2 {
    ${fonts.labGrotesqueBold};
  }

  b {
    ${fonts.labGrotesqueBold};
  }

  i {
    font-style: italic;
  }

  a {
    color: inherit;
  }

  p {
    margin-bottom: 25px;
    :last-child {
      margin-bottom: 0;
    }
  }
`

const USPBlock = styled.div`
  color: ${colors.grey};
  font-size: 16px;
  line-height: 25px;
  margin-top: 21px;

  h2 {
    ${fonts.labGrotesqueBold};
  }

  @media (min-width: ${breakpoints.md}) {
    display: flex;
    flex-wrap: wrap;

    h2 {
      min-width: 100%;
    }
  }
`

const Point = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 16px;
  line-height: 25px;
  @media (min-width: ${breakpoints.md}) {
    ${fonts.labGrotesqueBold};
    font-size: 12px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    display: ${(props) => (props.icon ? "block" : "block")};
    max-width: ${(props) => props.icon && "125px"};
    margin: ${(props) => props.icon && "auto"};
  }
`

const PointIcon = styled.div`
  text-align: center;
  img {
    width: 95px;
    height: 95px;
    margin-bottom: 36px;
  }
`

const USPGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 55px;
  grid-row-gap: 40px;
  margin-top: 102px;
  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: ${props => `repeat(${props.desktopGrid}, 1fr)`};
  }
`

const TextPoints = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
`

const ProductGallery = styled.div`
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    max-width: 510px;
  }
`

const Intro = (props) => {
  const { product, content, ratingSummary } = props
  const [quantity, setQuantity] = useState(1);
  const [sellingPlanId, setSellingPlanId] = useState(null);

  const { name, variants } = product
  const { subHeader, madeIn, size, productDetails, usp } = content

  let rating = ratingSummary.rating;
  let reviewTotal = ratingSummary.count;
  let stars = []

  const roundedRating = Math.ceil(rating);

  for (let i = 1; i <= roundedRating; i++) {
    stars.push(<img src={Star} alt="star" key={i} />)
  }

  const handleQuantity = (value) => {
    let newQuantity = quantity + value

    if (newQuantity <= 0) {
      newQuantity = 1
    }

    setQuantity(newQuantity)
  }
  
  const handleSellingPlanChange = (sellingPlan) => {
    if (sellingPlan) {
      setSellingPlanId('gid://shopify/SellingPlan/'+sellingPlan.id)
    } else {
      setSellingPlanId(null)
    }
  }

  let withIcon = []
  let withoutIcon = []

  usp.forEach((point) => {
    if (point.icon) {
      withIcon.push(point)
    } else {
      withoutIcon.push(point)
    }
  })

  let productMainMedia
  let productCarouselMedias = []

  if (content.productMedias) {
    productMainMedia = content.productMedias[0]
    if (content.productMedias.length > 1) {
      productCarouselMedias = content.productMedias.slice(
        1,
        content.productMedias.length
      )
    }
  }

  const variantId = variants[0].shopifyId;

  return (
    <IntroContainer>
      <ContentContainer>
        <Row>
          <Col md={6}>
            <ProductGallery>
              <Mobile gallery={content.productMedias} product={product} />
              <Desktop gallery={content.productMedias} product={product} />
            </ProductGallery>
          </Col>
          <Col md={5} mdOffset={1} xl={6} xlOffset={0}>
            <ProductInfo>
              <ProductName>{name}</ProductName>
              {subHeader && <SubHeader>{subHeader}</SubHeader>}
              <Reviews>
                {stars} <span>({reviewTotal}) Reviews</span>
              </Reviews>
              <PriceSection>
                <Price>{getLocalisedProductPrice(product)}</Price>
                <OriginSize>
                  <div>Made in {madeIn}</div>
                  <div>
                    {size.map((sizeInfo, index) => {
                      const isLast = index === size.length - 1
                      return (
                        <span key={index}>
                          {sizeInfo} {!isLast && " | "}
                        </span>
                      )
                    })}
                  </div>
                </OriginSize>
              </PriceSection>
              <PlanPicker 
                productHandle={product.slug} 
                currencyCode={product.price.minVariantPrice.currencyCode} 
                onSellingPlanChange={handleSellingPlanChange}
              />
              <AddToCartContainer>
                <Quantity>
                  <QuantityButton onClick={() => handleQuantity(-1)}>
                    <img src={Remove} alt="Remove quantity" />
                  </QuantityButton>
                  {quantity}
                  <QuantityButton onClick={() => handleQuantity(1)}>
                    <img src={Add} alt="Add quantity" />
                  </QuantityButton>
                </Quantity>
                <AddToCart>
                  <AddToBagButton productId={variantId} quantity={quantity} sellingPlanId={sellingPlanId}>
                    Add to Cart
                  </AddToBagButton>
                </AddToCart>
              </AddToCartContainer>
              <ProductDetails>
                {renderRichText(productDetails)}
              </ProductDetails>
              {usp && (
                <USPBlock>
                  <h2>Good to know:</h2>
                  <TextPoints>
                    {withoutIcon.map((point, index) => {
                      return <Point key={index} grid>{point.point}</Point>
                    })}
                  </TextPoints>
                </USPBlock>
              )}
            </ProductInfo>
          </Col>
          <Col hiddenMdDown={"true"}>
            <USPGrid desktopGrid={withIcon?.length >= 6 ? 6 : withIcon.length}>
              {withIcon.map((point, index) => {
                return (
                  <PointIcon key={index}>
                    <img src={point.icon.file.url} alt={point.point} />
                    <Point icon>{point.point}</Point>
                  </PointIcon>
                )
              })}
            </USPGrid>
          </Col>
        </Row>
      </ContentContainer>
    </IntroContainer>
  )
}

export default Intro
