import React, { useRef, useState } from "react"
import styled from "styled-components"
import colors from "../../../styles/colors"
import breakpoints from "../../../styles/breakpoints"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"

const MobileGallery = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`

const ProductVideo = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  video {
    position: absolute;
    width: 150%;
    height: 150%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const ProductImageContainer = styled.div`
  width: 100%;
  height: 420px;
  border-radius: 15px;
  overflow: hidden;
  /* background: ${colors.ivory}; */
  cursor: grab;

  :active {
    cursor: grabbing;
  }

  @media (min-width: ${breakpoints.md}) {
    height: 680px;
  }
`

const ProductImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  img {
    object-fit: ${(props) => props.objectFit} !important;
    width: 100% !important;
    height: 100% !important;
    padding: ${(props) =>
      props.objectFit === "contain" && "38px 38px"} !important;
  }
`


const StyledSlider = styled(Slider)`
  margin-bottom: 46px;
`

const DotsContainer = styled.div`
  position: absolute;
  bottom: 15px;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 39px;
`

const CustomDot = styled.div`
  width: 7px;
  height: 7px;
  border: 1px solid ${colors.grey};
  border-radius: 50%;
  margin-left: 11px;
  background: ${(props) => (props.active ? colors.grey : "transparent")};
  cursor: pointer;
`



const Mobile = (props) => {
  const { gallery, product } = props

  const [slideIndex, setSlideIndex] = useState(0)
  let sliderRef = useRef(null)

  const settings = {
    arrows: false,
    beforeChange: (current, next) => setSlideIndex(next),
  }

  return (
    <MobileGallery>
      <StyledSlider {...settings} ref={(ref) => (sliderRef = ref)}>
        {gallery.map((image, index) => {
          return (
            <ProductImageContainer key={index}>
              {image.file.contentType.includes("video")? (
                <ProductVideo>
                  <video
                    src={image.file.url}
                    autoPlay
                    muted
                    loop
                    playsInline
                    preload="metadata"
                    aria-label={product.title}
                  />
                </ProductVideo>
              ) : (
                <ProductImage
                  image={image.gatsbyImageData}
                  alt={product.name}
                  objectFit={
                    image.file.contentType === "image/png" ? "contain" : "cover"
                  }
                />
              )}
            </ProductImageContainer>
          )
        })}
      </StyledSlider>
      <DotsContainer>
        {gallery.map((image, index) => {
          return (
            <CustomDot
              key={index}
              active={index === slideIndex}
              onClick={() => sliderRef.slickGoTo(index)}
            />
          )
        })}
      </DotsContainer>
    </MobileGallery>
  )
}

export default Mobile
