import React, { useEffect, useRef, useState } from "react"
import styled, { css, keyframes } from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import ContentContainer from "../Core/Container/ContentContainer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Col, Row } from "styled-bootstrap-grid"
import { GatsbyImage } from "gatsby-plugin-image"
import DetailPopup from "./DetailPopup"
import { isBrowser } from "../../services/browser"

const StyledContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 86px;
  }
`
const StyledRow = styled(Row)`
  border-top: 1px solid ${colors.grey};
  padding-bottom: 94px;
  @media (min-width: ${breakpoints.md}) {
    border-top: 1px solid ${colors.grey};
    padding-top: 62px;
    padding: 62px 0 0 13px;
  }
`

const StyledCol = styled(Col)`
  @media (min-width: ${breakpoints.md}) {
    ${(props) => (props.left ? "padding-right: 42px" : "padding-left: 42px")};
  }
`

const DropdownContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    margin-top: 13px;
  }
`

const Category = styled.div`
  border-bottom: 1px solid ${colors.grey};
`

const CategoryTitle = styled.div`
  ${fonts.labGrotesqueBold};
  color: ${colors.grey};
  font-size: 19px;
  letter-spacing: 3.8px;
  padding: 38px 0 35px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 30px;
  cursor: pointer;

  a {
    color: inherit;
    text-decoration: none;
  }
`

const CategoryButton = styled.div`
  width: 7px;
  height: 7px;
  border: 1px solid ${colors.grey};
  border-radius: 50%;
  background: ${(props) => (props.active ? colors.grey : "transparent")};
`

const Drawer = styled.div`
  height: ${(props) => (props.open ? "100%" : "0")};
  overflow: hidden;
`

const PointBlock = styled.div`
  margin: 19px auto 102px;
`

const Point = styled.div`
  ${fonts.labGrotesqueBold};
  color: ${colors.grey};
  font-size: 19px;
  letter-spacing: 3.8px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (props.isLast ? "0" : "13px")};
`

const PercentagePoint = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (props.isLast ? "0" : "56px")};
`

const Percentage = styled.div`
  ${fonts.canelaThin};
  font-size: 24px;
  line-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const PercentCircle = styled.div`
  width: 80px;
  height: 80px;
  margin-right: 33px;
  position: relative;
  svg {
    width: 80px;
  }
`

const ProgressAnimation = (strokeDasharray) => keyframes`
  0% {
    stroke-dasharray: 0 100;
  }
  100% {
    stroke-dasharray: ${strokeDasharray} 0;
  }
`

const Circle = styled.path`
  fill: none;
  stroke: ${(props) => (props.bg ? "rgba(189,146,123, 0.2)" : colors.clay)};
  stroke-width: 2.5;
  animation: ${(props) =>
    !props.bg &&
    css`
      ${ProgressAnimation(props.strokeDasharray)} 1.5s ease-out forwards
    `};
`

const Icon = styled.img`
  width: 11px;
  height: 11px;
  margin-right: 16px;
`

const DetailCopy = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 89px;
  margin-top: 3px;

  h3 {
    ${fonts.labGrotesqueBold};
    font-size: 19px;
    letter-spacing: 3.8px;
    text-transform: uppercase;
    margin-bottom: 17px;
  }

  ul {
    li {
      list-style: disc;
      margin-left: 25px;
      margin-bottom: 50px;
      :last-of-type {
        margin-bottom: 0;
      }
      :first-of-type {
        margin-bottom: 50px;
      }
    }
    margin-bottom: 89px;
    :last-of-type {
      margin-bottom: 0;
    }
    :first-of-type {
      margin-bottom: 89px;
    }
  }

  p {
    margin-bottom: 50px;
    :last-of-type {
      margin-bottom: 0;
    }
  }

  b {
    ${fonts.labGrotesqueBold};
  }
`

const MediaContainer = styled.div`
  border-radius: 15px;
  max-width: 609px;
  height: 642px;
  overflow: hidden;
  position: relative;
  position: sticky;
  top: calc(50% - 280px);
`

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  img {
    width: 100% !important;
    height: 100% !important;
  }
`

const DropdownImage = styled(GatsbyImage)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: all 0.5s;
`

const VoiceButton = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: ${(props) => (props.play ? colors.grey : colors.clay)};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 23px;
  left: 30px;
  cursor: pointer;
  transition: all 0.5s;
  z-index: 1;

  :hover {
    background: ${colors.grey};
  }

  span {
    ${fonts.canelaThinItalic};
    color: ${colors.white};
    font-size: 24px;
    line-height: 120%;
  }
`

const StyledVideo = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: all 0.5s;

  video {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`

const Dropdowns = (props) => {
  const { dropdowns } = props
  const [open, setOpen] = useState(false)
  const [openIndex, setOpenIndex] = useState(0)
  const [play, setPlay] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const [ingredientPopupOpen, setIngredientPopupOpen] = useState(false)
  const [ingredientIndex, setIngredientIndex] = useState(null)
  let audioRef = useRef(null)
  let ingredients = []

  const handleIngredientPopup = (index) => {
    if (ingredientPopupOpen) {
      setIngredientIndex(null)
      setIngredientPopupOpen(false)
    } else {
      setIngredientPopupOpen(true)
      setIngredientIndex(index)
    }
  }

  const renderDetail = (detail, index, array) => {
    const { __typename: type } = detail

    if (type === "ContentfulTextBlock") {
      const { content } = detail
      return (
        <DetailCopy key={index}>
          {renderRichText(content)}
        </DetailCopy>
      )
    }
    if (type === "ContentfulPoint") {
      const { percentage, icon, point } = detail
      const isLast = index === array.length - 1
      if (percentage) {
        let percent = (percentage * 226) / 100
        return (
          <PercentagePoint isLast={isLast} key={index}>
            <PercentCircle>
              <svg viewBox="0 0 80 80">
                <Circle bg d="M40 4 a 36 36 0 0 1 0 72 a 36 36 0 0 1 0 -72" />
                <Circle
                  d="M40 4 a 36 36 0 0 1 0 72 a 36 36 0 0 1 0 -72"
                  strokeDasharray={`${percent}, 226`}
                />
              </svg>
              <Percentage>{percentage}%</Percentage>
            </PercentCircle>
            {point}
          </PercentagePoint>
        )
      } else if (icon) {
        return (
          <Point key={index} isLast={isLast}>
            <Icon src={icon.file.url} alt={point} /> {point}
          </Point>
        )
      } else {
        return <Point>{point}</Point>
      }
    }
  }
  const handleCategory = (index, popup = false) => {
    if (index === openIndex) {
      setOpenIndex(0)
      setOpen(!open)
    } else {
      if (popup) {
        setOpenIndex(0)
        setOpen(false)
      } else {
        setOpenIndex(index)
        setOpen(true)
      }
    }
  }

  const handleDropdownOpen = (popup, index) => {
    if (popup) {
      setPopupOpen(true)
    } else {
      handleCategory(index)
    }
  }

  useEffect(() => {
    if (isBrowser()) {
      if (!popupOpen && openIndex !== 0) {
        const globalContainer = document.getElementById(`dropdown-${openIndex}`)
        if (globalContainer) {
          const yOffset = -250
          const y =
            globalContainer.getBoundingClientRect().top +
            window.pageYOffset +
            yOffset
          window.scrollTo({ top: y })
        }
      }
    }
  }, [openIndex])
  let mainImage
  if (dropdowns && dropdowns.length >= 1) {
    mainImage = dropdowns[0].media
  }
  const handleAudio = () => {
    if (play) {
      setPlay(false)
      audioRef.pause()
    } else {
      setPlay(true)
      audioRef.play()
    }
  }

  const handleCoaPopup = () => {
    setPopupOpen(!popupOpen)
  }

  return (
    <StyledContainer>
      <ContentContainer>
        <StyledRow>
          <StyledCol md={6} left>
            <DropdownContainer>
              {dropdowns.map((dropdown, index) => {
                let isImage = dropdown.__typename === "ContentfulImageBlock"
                const { title, details, ingredientsListTitle } = dropdown
                const isActive = open && openIndex === index
                return (
                  <Category key={index}>
                    <CategoryTitle
                      onClick={() => handleDropdownOpen(isImage, index)}
                    >
                      {title}
                      <CategoryButton active={isActive ? 1 : 0} />
                    </CategoryTitle>
                    {!isImage ? (
                      <Drawer
                        id={`dropdown-${index.toString()}`}
                        open={isActive ? 1 : 0}
                      >
                        <PointBlock>
                          {details.map((detail, index) => {
                            return (
                              <React.Fragment key={index}>
                                {renderDetail(
                                  detail,
                                  index,
                                  details,
                                  ingredientsListTitle
                                )}
                              </React.Fragment>
                            )
                          })}
                        </PointBlock>
                      </Drawer>
                    ) : (
                      <React.Fragment key={index}>
                        <DetailPopup
                          {...dropdown}
                          handlePopup={handleCoaPopup}
                          open={popupOpen}
                        />
                      </React.Fragment>
                    )}
                  </Category>
                )
              })}
            </DropdownContainer>
          </StyledCol>
          <StyledCol md={6} hiddenMdDown={true}>
            <MediaContainer>
              {mainImage && (
                <StyledImage image={mainImage.gatsbyImageData} alt="Main Image" />
              )}
              {dropdowns.map((dropdown, index) => {
                let isImage = dropdown.__typename === "ContentfulImageBlock"
                const { media, audio, title } = dropdown
                const isActive = openIndex === index
                return (
                  !isImage && (
                    <React.Fragment key={index}>
                      {media.gatsbyImageData ? (
                        <DropdownImage
                          image={media.gatsbyImageData}
                          alt={title}
                          visible={isActive}
                        />
                      ) : (
                        <StyledVideo visible={isActive}>
                          {isActive ? (
                            <video
                              src={media.file.url}
                              autoPlay
                              muted
                              loop
                              playsInline
                            />
                          ) : null}
                        </StyledVideo>
                      )}
                      {audio && isActive && (
                        <VoiceButton onClick={() => handleAudio()}>
                          <span>{play ? "off" : "on"}</span>
                          <audio
                            src={audio.file.url}
                            typeof={audio.contentType}
                            ref={(ref) => (audioRef = ref)}
                          />
                        </VoiceButton>
                      )}
                    </React.Fragment>
                  )
                )
              })}
            </MediaContainer>
          </StyledCol>
        </StyledRow>
      </ContentContainer>
    </StyledContainer>
  )
}

export default Dropdowns
