import React, { useEffect, useState } from "react"
import Popup from "reactjs-popup"
import styled from "styled-components"
import colors from "../../styles/colors"
import { GatsbyImage } from "gatsby-plugin-image"
import { isBrowser } from "../../services/browser"
import CloseIcon from "../../resources/img/ui/dark/close-dark.svg"

const contentStyle = {
  width: "100%",
  height: "auto",
  display: "flex",
  alignItems: "center",
  maxWidth: "602px",
}
const contentStyleMobile = {
  width: "100%",
  height: "auto",
  marginTop: "86px",
}

const overlayStyle = {
  background: colors.ivory,
  width: "100vw",
  height: "100vh",
  paddingLeft: "27px",
  paddingRight: "27px",
  cursor: "pointer"
}

const ImageContainer = styled.div`
  width: 100%;
  max-width: 602px;
  margin: auto;
  cursor: default;
  

  div {
    img {
      object-fit: contain !important;
    }
  }
`

const Close = styled.div`
  position: fixed;
  padding: 8px;
  top: 23px;
  right: 18px;
  cursor: pointer;

  img {
    width: 19px;
    height: 19px;
  }
`

const DetailPopup = (props) => {
  const { image, open, handlePopup, altText } = props
  const [width, setWidth] = useState(0)

  const getWindowWidth = () => {
    setWidth(window.innerWidth)
  }

  const getContentStyle = () => {
    if (width >= 768) {
      return contentStyle
    } else {
      return contentStyleMobile
    }
  }

  useEffect(() => {
    if (isBrowser()) {
      getWindowWidth()
    }
  }, [])

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener("resize", getWindowWidth())
    }
    return () => {
      window.removeEventListener("resize", getWindowWidth())
    }
  })

  return (
    <Popup
      open={open}
      overlayStyle={overlayStyle}
      contentStyle={getContentStyle()}
      closeOnDocumentClick
      onClose={() => handlePopup()}
      closeOnEscape
      lockScroll
    >
      <Close onClick={() => handlePopup()}>
        <img src={CloseIcon} alt="Close popup" />
      </Close>
      <ImageContainer>
        <GatsbyImage image={image.gatsbyImageData} alt={altText} />
      </ImageContainer>
    </Popup>
  )
}

export default DetailPopup
