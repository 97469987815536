import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Intro from "../components/PDP/Intro"
import Dropdowns from "../components/PDP/Dropdowns"
import LineDivider from "../components/Core/LineDivider"
import Carousel from "../components/Core/Carousel/Carousel"
import ReviewsBlock from "../components/Core/Reviews/ReviewsBlock"
import BlogCarousel from "../components/Core/BlogCarousel/BlogCarousel"

const ProductTemplate = (props) => {
  const { data, location } = props
  const path = location.pathname
  const { product, content, reviews, stampedReviews, ratingSummary } = data
  const { blocks } = content
  const pageContents = []
  const dropdowns = []

  blocks.forEach((block, index) => {
    const type = block.__typename
    if (type === "ContentfulProductInformation") {
      dropdowns.push(block)
    }
    if (type === "ContentfulImageBlock") {
      dropdowns.push(block)
    }
    if (
      dropdowns.length !== 0 &&
      index ===
        blocks
          .map((el) => el.__typename)
          .lastIndexOf("ContentfulProductInformation")
    ) {
      pageContents.push(
        <Dropdowns dropdowns={dropdowns} location={location} key={index} />
      )
    }

    if (type === "ContentfulLineDivider") {
      pageContents.push(<LineDivider {...block} key={index} />)
    }

    if (type === "ContentfulCarouselBlock") {
      pageContents.push(<Carousel {...block} key={index} pattern />)
    }

    if (type === "ContentfulArticleCarouselBlock") {
      pageContents.push(<BlogCarousel {...block} key={index} limited />)
    }
  })

  return (
    <Layout>
      <Seo
        title={content.metaTitle}
        description={content.metaDescription}
        path={path}
      />
      <Intro
        product={product}
        content={content}
        ratingSummary={ratingSummary}
      />
      {pageContents}
      <ReviewsBlock
        reviews={reviews.nodes}
        stampedReviews={stampedReviews.edges}
        ratingSummary={ratingSummary}
        pdp
      />
    </Layout>
  )
}

export default ProductTemplate

export const pageQuery = graphql`
  query ProductTemplateQuery($slug: String!, $contentBlockId: String!) {
    product: shopifyProduct(handle: { eq: $slug }) {
      id
      product_id: shopifyId
      name: title
      description
      price: priceRangeV2 {
        minVariantPrice {
          amount
          currencyCode
        }
      }
      variants {
        shopifyId
      }
      slug: handle
      images {
        gatsbyImageData(width: 1080)
      }
    }
    content: contentfulProductContent(contentful_id: { eq: $contentBlockId }) {
      id
      metaTitle
      metaDescription
      subHeader
      madeIn
      size
      productDetails {
        raw
      }
      usp {
        point
        icon {
          file {
            url
          }
        }
      }
      productMedias {
        file {
          contentType
          url
        }
        gatsbyImageData(width: 1080)
      }
      blocks {
        __typename
        ... on ContentfulProductInformation {
          id
          title
          media {
            gatsbyImageData(width: 1218)
            file {
              url
              contentType
            }
          }
          #audio {
          #  file {
          #    contentType
          #    url
          #  }
          #}
          details {
            ... on ContentfulPoint {
              id
              percentage
              point
              icon {
                file {
                  url
                }
              }
            }
            ... on ContentfulTextBlock {
              id
              content {
                raw
              }
            }
          }
        }
        ... on ContentfulLineDivider {
          id
          points {
            point
          }
        }
        ... on ContentfulImageBlock {
          id
          title
          altText
          image {
            gatsbyImageData(width: 1204)
          }
        }
        ... on ContentfulArticleCarouselBlock {
          id
          header
          subHeader {
            raw
          }
          filters {
            __typename
            ... on ContentfulCategory {
              title
            }
          }
          slides {
            __typename
            ... on ContentfulArticle {
              category {
                title
              }
              slug
              title
              thumbnail {
                gatsbyImageData(width: 636, aspectRatio: 1)
              }
            }
          }
          ctaLabel
          ctaLink
        }
        ...carouselBlockFragment
      }
    }
    ratingSummary: stampedRatingSummary(productId: { eq: "7641585484006" }) {
      id
      badge
      rating
      count
    }
    stampedReviews: allStampedReview(
      filter: {
        review: { productId: { eq: 7641585484006 }, reviewState: { eq: 1 } }
      }
    ) {
      edges {
        node {
          id
          review {
            id
            rating
            title
            productTitle
            body
            author
            dateCreated: dateCreated(formatString: "MM/DD/YYYY")
            date: dateCreated(formatString: "YYYYMMDD")
          }
        }
      }
    }
    reviews: allContentfulProductReview(
      filter: { productSlug: { eq: $slug } }
    ) {
      nodes {
        name
        username
        age
        date(formatString: "D/M/YYYY")
        title
        location
        featured
        recommend
        rating
        productSlug
        profileImage {
          gatsbyImageData(width: 146, aspectRatio: 1)
        }
        content {
          raw
        }
      }
    }
  }
`
