import React, { useState, useEffect } from 'react';
import styled from "styled-components"
import colors from "../../styles/colors"
import { getLocalisedPrice } from '../../services/product/price';

const PlanPickerContainer = styled.fieldset`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const PlanPickerGroup = styled.label`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  cursor: pointer;
`

const PlanPickerGroupRadio = styled.input`
  margin: 0;
  padding: 0;
  accent-color: ${colors.grey};
`

const PlanPickerTitle = styled.span`
  font-weight: 600;
`

const PlanPickerPrice = styled.span`
  font-weight: 600;
`

const PlanPickerPriceSave = styled.span`
  padding: 0.125rem 0.5rem;
  font-size: 14px;
  border: 1px solid ${colors.grey};
  background: ${colors.grey};
  color: ${colors.ivory};
  border-radius: 30px;
`

const PlanPickerFrequencyContainer = styled.div`
  width: 100%;
`

const PlanPickerFrequencySelect = styled.select`
  display: block;
  width: 100%;
  margin-left: 1rem;
  padding: 0.5rem;
  border: 1px solid ${colors.grey};
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=) no-repeat 98% 50%, transparent;
  max-width: 320px;
  border-radius: 15px;
  outline: none;
  box-shadow: none;
  appearance: none;
`

const APP_ID = 'SKIO';

const config = {
  shopifyShopDomain: process.env.SHOPIFY_SHOP_DOMAIN,
};

const purchaseTypeLabels = {
  oneTime: 'one-time',
  subscription: 'subscription'
}

const PlanPicker = ({ productHandle, onSellingPlanChange, currencyCode }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [product, setProduct] = useState(null);
  const [purchaseType, setPurchaseType] = useState(purchaseTypeLabels.oneTime);
  
  const [sellingPlans, setSellingPlans] = useState(null);
  const [selectedSellingPlan, setSelectedSellingPlan] = useState(null);
  
  useEffect(() => {
    fetchProduct(productHandle)
      .then((product) => {
        setSellingPlans(product.selling_plan_groups.filter(spg => spg.app_id === APP_ID)[0].selling_plans)
        setProduct(product);
        setIsLoaded(true);
        console.log('product', product)
      })
      .catch((e) => console.error('Error in retrieving product', {...e}))
  }, []);
  
  useEffect(() => {
    if (purchaseType === purchaseTypeLabels.subscription) {
      setSelectedSellingPlan(sellingPlans[0])
    }
  }, [purchaseType]);
  
  const onSellingPlanSelect = (sellingPlanId) => {
    const sellingPlan = sellingPlans.find(sp => sp.id.toString() === sellingPlanId);
    if (!sellingPlan) return
    setSelectedSellingPlan(sellingPlan);
  }
  
  useEffect(() => {
    onSellingPlanChange && onSellingPlanChange(selectedSellingPlan);
  }, [selectedSellingPlan]);
  
  const subscriptionPrice = getSellingPlanPrice(product?.variants[0].price, sellingPlans && sellingPlans[0]);
  
  return isLoaded ? (
    <PlanPickerContainer>
      Purchase Options:
      <PlanPickerGroup>
        <PlanPickerGroupRadio 
          type="radio" 
          value={purchaseTypeLabels.oneTime} 
          checked={purchaseType === purchaseTypeLabels.oneTime}
          onChange={() => setPurchaseType(purchaseTypeLabels.oneTime)}
        />
        <PlanPickerTitle>One-time - </PlanPickerTitle>
        <PlanPickerPrice>{getLocalisedPrice(product.variants[0].price / 100, currencyCode)}</PlanPickerPrice>
      </PlanPickerGroup>
      <PlanPickerGroup>
        <PlanPickerGroupRadio 
          type="radio"
          value={purchaseTypeLabels.subscription} 
          checked={purchaseType === purchaseTypeLabels.subscription}
          onChange={() => setPurchaseType(purchaseTypeLabels.subscription)}
        />
        <PlanPickerTitle>Subscription - </PlanPickerTitle>
        <PlanPickerPrice>{getLocalisedPrice(subscriptionPrice.total / 100, currencyCode)}</PlanPickerPrice>
        <PlanPickerPriceSave>Save {Math.round(subscriptionPrice.percentageOff)}%</PlanPickerPriceSave>
        {
          purchaseType === purchaseTypeLabels.subscription ? (
            <PlanPickerFrequencyContainer>
              <PlanPickerFrequencySelect 
                name="selling_plan"
                value={selectedSellingPlan?.id}
                onChange={(e) => onSellingPlanSelect(e.target.value)}
              >
                { sellingPlans.map((sp) => <option key={sp.id} value={sp.id}>{sp.name}</option>) }
              </PlanPickerFrequencySelect>
            </PlanPickerFrequencyContainer>
          ) : null
        }
      </PlanPickerGroup>
    </PlanPickerContainer>
  ) : null
}


const fetchProduct = (handle) => {
  return fetch(`https://${config.shopifyShopDomain}/products/${handle}.js`)
  .then((response) => response.json())
  .then((product) => product);
}

const getSellingPlanPrice = (variantPrice, sellingPlan) => {
  if (!variantPrice || !sellingPlan) return
  switch (sellingPlan.price_adjustments[0].value_type) {
    case 'price':
      return {
        total: sellingPlan.price_adjustments[0].value,
        percentageOff: 100 - (sellingPlan.price_adjustments[0].value * 100 / variantPrice)
      }
  }
}

export default PlanPicker