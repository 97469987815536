import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import colors from "../../../styles/colors"
import breakpoints from "../../../styles/breakpoints"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"

const DesktopGallery = styled.div`
  display: none;
  position: relative;
  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }
`

const ProductImageContainer = styled.div`
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  /* background: ${colors.ivory}; */
  position: relative;
  height: 680px;
  opacity: ${(props) => (!props.main ? 1 : props.visible ? 1 : 0)};
  transition: opacity 1s ease-in;
`

const ProductImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  img {
    object-fit: ${(props) => props.objectFit} !important;
    width: 100% !important;
    height: 100% !important;
    padding: ${(props) =>
      props.objectFit === "contain" && "38px 38px"} !important;
  }
`

const SliderContainer = styled.div`
  position: absolute;
  bottom: ${(props) => (props.large ? "0" : "42px")};
  left: ${(props) => (props.large ? "0" : "-15%")};
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform-origin: bottom left;
  border-radius: 15px;
  transform: ${(props) => (props.large ? "scale(1)" : "scale(0.25)")};
  transition: all 1s ease-in;
  cursor: ${(props) => (props.large ? "grab" : "pointer")};
`

const StyledSlider = styled(Slider)`
  height: 100%;
  width: 100%;
`

const DotsContainer = styled.div`
  position: absolute;
  right: 0;
  z-index: 100;
  display: flex;
  width: 100%;
  padding: 0 39px;
  justify-content: flex-end;
  bottom: 29px;
`

const CustomDot = styled.div`
  width: 7px;
  height: 7px;
  border: 1px solid ${colors.grey};
  border-radius: 50%;
  margin-left: 11px;
  background: ${(props) => (props.active ? colors.grey : "transparent")};
  cursor: pointer;
`

const ProductVideo = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  video {
    position: absolute;
    width: ${(props) => (props.hover ? "auto" : "150%")};
    height: ${(props) => (props.hover ? "auto" : "150%")};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Desktop = (props) => {
  const { gallery, product } = props

  const [slideIndex, setSlideIndex] = useState(0)
  const [carouselActive, setCarouselActive] = useState(false)

  let sliderRef = useRef(null)
  let sliderContainerRef = useRef(null)
  let customDotRef = useRef(null)

  const settings = {
    arrows: false,
    swipe: carouselActive ? true : false,
    beforeChange: (current, next) => setSlideIndex(next),
  }

  let productMainMedia
  let productCarouselMedias = []

  if (gallery) {
    productMainMedia = gallery[0]
    if (gallery.length > 1) {
      productCarouselMedias = gallery.slice(1, gallery.length)
    }
  }

  useEffect(() => {
    if (sliderContainerRef && customDotRef) {
      const handleClickOutside = (event) => {
        if (
          !sliderContainerRef?.contains(event.target) &&
          !customDotRef?.contains(event.target)
        ) {
          setCarouselActive(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }
  })
  return (
    <DesktopGallery>
      <ProductImageContainer main visible={!carouselActive ? 1 : 0}>
        {productMainMedia.file.contentType.includes("video") ? (
          <ProductVideo>
            <video
              src={productMainMedia.file.url}
              autoPlay
              muted
              loop
              playsInline
              preload="metadata"
              aria-label={product.title}
            />
          </ProductVideo>
        ) : (
          <ProductImage
            image={productMainMedia.gatsbyImageData}
            alt={product.name}
            objectFit={
              productMainMedia.file.contentType === "image/png"
                ? "contain"
                : "cover"
            }
          />
        )}
      </ProductImageContainer>
      <SliderContainer
        onClick={() => setCarouselActive(true)}
        large={carouselActive ? 1 : 0}
        ref={(ref) => (sliderContainerRef = ref)}
      >
        <StyledSlider
          {...settings}
          ref={(ref) => (sliderRef = ref)}
          large={carouselActive ? 1 : 0}
        >
          {productCarouselMedias.map((image, index) => {
            return (
              <ProductImageContainer key={index}>
                {image.file.contentType.includes("video") ? (
                  <ProductVideo hover>
                    <video
                      src={image.file.url}
                      autoPlay
                      muted
                      loop
                      playsInline
                      preload="metadata"
                      aria-label={product.title}
                    />
                  </ProductVideo>
                ) : (
                  <ProductImage
                    image={image.gatsbyImageData}
                    alt={product.name}
                    objectFit={
                      image.file.contentType === "image/png"
                        ? "contain"
                        : "cover"
                    }
                  />
                )}
              </ProductImageContainer>
            )
          })}
        </StyledSlider>
      </SliderContainer>
      <DotsContainer ref={(el) => (customDotRef = el)}>
        {productCarouselMedias &&
          productCarouselMedias.length > 1 &&
          productCarouselMedias.map((image, index) => {
            return (
              <CustomDot
                key={index}
                active={index === slideIndex}
                onClick={() => sliderRef.slickGoTo(index)}
              />
            )
          })}
      </DotsContainer>
    </DesktopGallery>
  )
}

export default Desktop
